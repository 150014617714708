<template>
  <div>
    <div class="list">
      <div class="item" v-for="(item, index) in result" :key="index" :style="{backgroundImage: 'url(' + item.image + ')' }">
        <router-link :to="{ path: '/product/' + item.id }" target="_blank">
          <div class="CommonWidth">
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="exp">{{ item.info }}</div>
              <div class="button">了解更多</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import articleIndex from "../../data/articleIndex";
export default {
  name: "Product",
  components: {},
  created() {
    this.result = articleIndex.product.reverse();
  },
  data() {
    return {
      result: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.item {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flow-root;
  .info{
    margin-top: 120px;
    font-size: 48px;
    color: #ffffff;
    .title{
      font-size: 48px;
    }
    .exp{
      margin-top: 10px;
      font-size: 18px;
    }
    .button{
      margin-top:50px;
      font-size: 14px;
      border: 2px solid #f4f4f4;
      float: left;
      border-radius: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}


@media screen and (max-width: 850px) {
}
</style>